<div class="containerMenu">
  <div class="sidebar" id="sidebar" #sidebar>
    <div class="sidebar-main-container">
      <div class="sidebar-header-container">
        <div class="sidebar-header">
          <div class="GenDLogo" [routerLink]="['/']" >
            <span> <img class="GenDLogoImage" src="../assets/images/logo_v2.png" /></span><span
              class="GenDHeading">Gen-D</span>
          </div>

          <span #pinIcon class="pin-icon pinned hide" id="pin-icon" (click)="togglePin()">
            <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
            <img src="../assets/images/pin.png" />
          </span>
          <span #unpinIcon class="pin-icon" id="unpin-icon" (click)="toggleUnPin()">
            <img src="../assets/images/unpin.png" />
          </span>


        </div>
        <!-- <br />    
    <div class="sidebar-section">
      <div class="sidebarSectionPart1">
        <div class="sidebarSectionPart2">
        </div>
        <div class="sidebarSectionPart3">
          <div class="sidebarSectionPart4">
            <div class="sidebarSectionImage">
              <img  src="../assets/images/table.png" />
            </div>
          </div>
          <div class="sidebarChat">
            Chat</div>
        </div>
      </div>
      <div class="sidebarChat2">
        <select class="sidebarChatDropdown" [(ngModel)]="systemModel" (change)="changeSystemModel()">
          <option value="4o">GPT 4o</option>
          <option value="3.5">GPT 3.5</option>
        </select>
      </div>
    </div>
    <br /><br/> -->
        <div class="sidebar-section">
          <div class="sidebarModel" 
          [routerLink]="['/']" 
          [ngClass]="{'selected-section': isSelected('/')}">
            <div style="display: flex; align-items: center;margin-left: -3px;">
              <img class="table-image" [src]="isSelected('/') ? '../assets/images/chat-messages-active.png' : '../assets/images/chat-messages.png'" />
              <span class="sidebarChat">{{lblChat}}</span>
            </div>
            <div class="tooltip-wrapper" [hidden]="!isSelected('/')">
              <select (click)="onClickSystemModel($event)" [ddsTooltip]="text" [tooltipInvokeType]="options.tooltipInvokeType"
                [tooltipType]="options.tooltipType" [tooltipSize]="options.tooltipSize"
                [tooltipPosition]="options.tooltipPosition" [tooltipIndent]="options.tooltipIndent"
                [tooltipHasBeak]="options.tooltipHasBeak" [tooltipIsDisabled]="options.tooltipIsDisabled"
                [tooltipIsOutsideClick]="options.tooltipIsOutsideClick" [tooltipIsDynamic]="options.tooltipIsDynamic"
                [tooltipCustomClass]="options.tooltipCustomClass" class="modeldropdown" [(ngModel)]="systemModel"
                (change)="changeSystemModel()" (mouseover)="showTooltip()" (mouseout)="hideTooltip()">
                <option value="gpt4o">GPT 4o</option>
                <option value="gpt3.5">GPT 3.5</option>
              </select>
            </div>
          </div>
        </div>

      </div>
      <div class="sidebar-body-container">
        <div class="sidebar-section agent-central-section">
          <br />
          <div class="sidebarSectionPart5" 
          [routerLink]="['/document-analyzer']" 
          [ngClass]="{'selected-section': isSelected('/document-analyzer')}"
           >
            <div class="sidebarSectionPart6">
              <img class="sidebarDocAnalyzerImage" src="../assets/images/document-analyzer.png" />
            </div>
            <!-- <div class="sidebarAgentCentral">
          Agent Central</div>
      </div> -->
            <div class="sidebarDocumentAnalyzer" >
              Document Analyzer</div>
          </div>
        </div>
        <!-- <hr />
    <div class="sidebar-section">
      <div class="sidebarRecentlyUsed">
        {{lblRecentlyUsedAgents}}</div>
      <div class="sidebarSectionPart7">
        <div class="sidebarSectionPart8">
          <img class="sidebarRecentlyUsedImage" src="../assets/images/RecentlyUsed.png" />
        </div>
        <div class="sidebarDocumentAnalyzer">
          Document Analyzer</div>
      </div>
      <div class="sidebarSectionPart9">
        <div class="sidebarSectionPart10">
          <img class="sidebarRecentlyUsedImage" src="../assets/images/RecentlyUsed.png" />
        </div>
        <div class="sidebarDeloitteVoice">
          Deloitte Voice</div>
      </div>
      <div class="sidebarSectionPart11">
        <div class="sidebarSectionPart12">
          <img class="sidebarRecentlyUsedImage" src="../assets/images/RecentlyUsed.png" />
        </div>
        <div class="sidebarEFS">
          EFS</div>
      </div>
    </div>  -->
        <!-- <hr />
        <div class="sidebar-section">

          <div class="sidebarSectionPart13">
            <div class="sidebarChatHistory">
              {{lblChatHistory}}</div>
            <div class="chatHistory">
          <div class="sidebarChatHistoryPrompts">
            Help me find a platform...</div>
          <div class="sidebarChatHistoryPrompts">
            Why everyone loves me? ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
          <div class="sidebarChatHistoryPrompts">
            How do I uncover a way ...</div>
        </div>
        
        <div class="sidebarSectionPart14">
        <div class="sidebarChatHistoryMessage">
          {{lblRecent10Conversations}}</div>
      </div> 
        </div>
        <br /> 
        <hr />  -->
        <div class="sidebar-section settings-section">
          <div class="sidebarSettingsLabel">
            {{lblSettings}}</div>
          <div class="sidebarSettings userCommunity" (click)="openUserCommunityLink()">
            {{lblUserCommunity}}</div>
          <div class="sidebarSettings">
            <a id="chatSettings" (click)="openSystemSettingsModal($event)">
              {{lblChatSettings}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content" #mainContent id="main-content"
    [ngClass]="{'modelPopUpEnables':appcomponentservice.isModelOpen}">
    <div id="subMainContent" [ngClass]="{ 'isLoaderActivated': loadingService.isLoading }">
      <div class="header-container">
        <div class="header-group">
          <div class="header-item logo-item" [routerLink]="['/']">
            <span> <img class="GenDLogoImage-3" src="../assets/images/logo_v2.png" /></span> &nbsp; <span
              class="GenDHeading-3">Gen-D</span>
          </div> 
          <div class="header-item item2">
            <button [ngClass]="{'disableNewChat': appcomponentservice.isSendButtonDisabled}" class="whatsnew btn btnNewChatStyle" (click)="newChat()" >{{lblNewChat}} <img class="new-chat-icon"
                src="../assets/images/plus-icon.png"></button>
          </div>
        </div>
        <div class="header-group">
          <div class="header-item">
            <button class="btn btnWhatsNewStyle" (click)="openWhatsNewModel()"><img src="../assets/images/whatsnewicon.png" /><span
                class="txtWhatsNewStyle"> {{lblWhatsNew}}</span></button>
          </div>
          <div class="header-item">
            <select id="lang" [value]="selectedLanguage" (change)="onLanguageChange($event)">
              <option>EN</option>
              <option>FR</option>
            </select>
          </div>
        </div>
      </div>
      <router-outlet />
    </div>
  </div>
</div>