import { Component, DebugElement } from '@angular/core';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.css']
})
export class WhatsnewComponent {
  lang1: string;
  private destroy = new Subject<void>();
  whatsnew1: string;
  whatsnew2: string;
  whatsnew3: string;
  whatsnew4: string;
  whatsnew5: string;
  userCommunityUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  userCommunityText: string;
  whatnew12: string;
  releaseNote: string;
  whatsNew: string;
  constructor( private modalService: ModalService, private i18n: I18nService,public appcomponentservice: AppComponentService
  ){}

  // @Input() isInverse: boolean;
  manageLocale(){
    this.i18n
    .getLocaleObs()
    .pipe(takeUntil(this.destroy))
    .subscribe((locale) => {
      switch (locale) {
        case "FR":
        this.lang1 = 'fr';
        this.whatsNew = "Quoi de neuf ?";
        this.releaseNote="Note de la version 2024-20-12 :";
        this.whatsnew1 = 'Présentation de la';
        this.userCommunityText = "Communauté des Utilisateurs de Gen-D";
        this.whatnew12 = "une communauté collaborative pour faire entendre votre voix";
        this.whatsnew2 = "Ajout des paramètres de discussion pour un contrôle utilisateur granulaire";
        this.whatsnew3 = 'Nouvelle interface utilisateur et éléments visuels';
        this.whatsnew4 = "Amélioration de l'expérience utilisateur et meilleure stabilité";
        this.whatsnew5 = 'Optimisation des performances avec des interactions plus intuitives';
        break;
      default:
        this.lang1 = 'en';
        this.whatsNew = "What's New";
        this.releaseNote="Release Note 2024-12-20 :";
        this.whatsnew1 = 'Introducing';
        this.userCommunityText = "Gen-D User Community";
        this.whatnew12 = ": a collaborative community to get your voice heard";
        this.whatsnew2 = 'Added Chat Settings for granular user control';
        this.whatsnew3 = 'Brand new UI and visual elements';
        this.whatsnew4 = 'Improved UX and product behavior ';
        this.whatsnew5 = 'Performance optimization along with more intuitive interaction';
        break;
    }
  });
}
  close() {
    this.modalService.close();
  }

  ngOnInit() {
  this.manageLocale();

    //centering chat settings modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
  }
}
