import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { AppComponentService } from '../Services/appcomponentservice';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.css']
})
export class PreviewPopupComponent {
  switcpopup: boolean;
  route: any;
  label1: string;
  previewText: string;
  temp1: string;
  temp2: string;
  temp3: string;
  temp4: string;
  preview_option1: string;
  preview_option2: string;
  lang1: string;
  private destroy = new Subject<void>();
  whatsNew: string;
  releaseNote: string;
  whatsnew1: string;
  userCommunityText: string;
  whatnew12: string;
  whatsnew2: string;
  whatsnew3: string;
  whatsnew4: string;
  whatsnew5: string;
  userCommunityUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';

constructor(    private modalService: ModalService,private i18n: I18nService,public appcomponentService:AppComponentService
  ) {}
  ngOnInit() {
     //centering feedback like modal
     const sidebar = document.getElementById("sidebar")
     if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
       this.appcomponentService.setIsPinChecked(true);
     }
     else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentService.setIsPinChecked(true);
      }
      else {
        this.appcomponentService.setIsPinChecked(false);
      }
    }
    this.manageLocale();
  }
    closePreview(){
      this.modalService.close();  
   }  
    openPreview(){
      window.open(environment.previewGenDURL,'_self');
    }
  
  manageLocale(){
    this.i18n
    .getLocaleObs()
    .pipe(takeUntil(this.destroy))
    .subscribe((locale) => {
      switch (locale) {
        case "FR":
        this.lang1 = 'en';
        this.label1 = "Exemples de questions "; 
        this.previewText = "En tant qu'utilisateur cible, vous êtes invité à explorer les dernières nouvelles fonctionnalités avec la version Beta de Gen-D. Cela vous tente ?";
        this.temp1="Capacité de mettre à jour le message et les paramètres du système Gen-D pour refléter votre persona sur GPT";
        this.temp2="Revoir et gérer votre historique de conversation";
        this.temp3="Support étendu pour HTML, XLS et XLSX sur l'analyseur de documents.";
        this.temp4="Recherche plus efficace sur les documents téléchargés.";
        this.preview_option1 = "Non, merci !";
        this.preview_option2 = "Oui, s’il vous plaît !";
        this.whatsnew1 = 'Présentation de la';
        this.userCommunityText = "Communauté des Utilisateurs de Gen-D";
        this.whatnew12 = "une communauté collaborative pour faire entendre votre voix";
        this.whatsnew2 = "Paramètres de discussion pour personnaliser le comportement de l'IA";
        this.whatsnew3 = 'Nouvelle interface utilisateur et éléments visuels';
        this.whatsnew4 = "Amélioration de l'expérience utilisateur et meilleure stabilité";
        this.whatsnew5 = 'Optimisation des performances';
        break;
      default:
        this.lang1 = 'en';
        this.previewText = "As a valued key user, you're invited to explore new features with Gen-D's Beta version. Interested?";
        this.temp1="Ability to update Gen-D system message and settings to reflect your on GPT persona";
        this.temp2="Review and manage with your conversation history";
        this.temp3="Extended support to HTML, XLS and XLSX on document analyzer";
        this.temp4="More efficient Search on uploaded documents";
        this.preview_option1 = "No, Thanks!";
        this.preview_option2 = "Yes, Please!";
        this.whatsnew1 = 'Introducing';
        this.userCommunityText = "Gen-D User Community";
        this.whatnew12 = ": a collaborative community to get your voice heard";
        this.whatsnew2 = 'Chat Settings to customize AI behavior ';
        this.whatsnew3 = 'Brand new UI and visual elements';
        this.whatsnew4 = 'Improved UX and product behavior ';
        this.whatsnew5 = 'Performance optimization';
        break;
    }
  });
}

   
}