<div class="feedbacklikeModel" [ngClass]="{'middlefeedBacklikeModelPopup': appcomponentService.isPinChecked}">
<div class="dds-modal-overlay" (click)="close()"></div>
 
<div ddsResize class="dds-modal" >
    <div class="dds-modal__header headerMargin">
        <img class="imgActionsOnDynamicContent imgSupportStaff" src="../assets/images/support_agent.png" />
        <span class="dds-modal__title txtFeedback">{{lblFeedback}}</span>
        <dds-button
            size="sm"
            icon="dds-icon_close"
            kind="silent"
            theme="dark"
            ariaLabel="Close modal"
            class="dds-modal__close ddsBtnSelf"
            [isInverse]="isInverse"
            (clicked)="close()">
        </dds-button>
    </div>

    <!-- <hr class="line1"> -->
 
    <div class="dds-modal__body bodyMargin">
        <div class="col-md-12 col-sm-12 col-xs-12 lblFeedbackQues">
            <label>{{lblLikedFeedbackInParticular}}</label>
            <div>
                <textarea class="feedbackBody" [(ngModel)]='feedbackRes' (input)="updateCharacterCount()"></textarea>
                <div class="charCount">{{feedbackRes.length}}/1000</div>
            </div>
        </div>
    </div>
    
    <div class="dds-modal__footer footerMargin">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            <div class="ext-center">
                <button class="btnSubmit" (click)="Submit()">{{lblSubmit}}</button>
            </div>
        </div>
    </div>
 
</div>
</div>