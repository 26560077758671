import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {

  constructor() { }
  private currentLanguage = new BehaviorSubject<string>('EN');

  setCurrentLanguage(language: string) {
    this.currentLanguage.next(language);
  }

  getCurrentLanguage() {
    return this.currentLanguage.asObservable();
  }
}