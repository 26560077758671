<div class="WhatsNewModel"  [ngClass]="{'middleWhatsNewModelPopup': appcomponentservice.isPinChecked}">
<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal">
    <div class="dds-modal__header headerMargin">
        <img class="supportAgentImage" src="../assets/images/whatsnewicon.png" />
        <span class="dds-modal__title" class="txtFeedback">{{whatsNew}}</span>
      
        <dds-button size="sm"
                  icon="dds-icon_close"
                  kind="silent"
                  theme="dark"
                  ariaLabel="Close modal"
                  class="dds-modal__close ddsBtnSelf"
                  
                  (clicked)="close()"></dds-button>
    </div>

    <hr class="line1">

    <div class="dds-modal__body bodyMargin">
        <div >
            <p class="txtCheckboxWhy">{{releaseNote}}</p>
            <p></p>
            <ul class="txtBulletPoints">
              <li>
                  {{whatsnew1}}
                  <a [href]="userCommunityUrl" target="_blank">{{ userCommunityText }}</a>                  
                  {{whatnew12}}
              </li>
              <li>{{whatsnew2}}</li>
              <li>{{whatsnew3}}</li>
              <li>{{whatsnew4}}</li>
              <li>{{whatsnew5}}</li>
            </ul>
          </div>
          
          

    </div>



</div>

</div>