import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading: boolean = false;

  setLoadingState(state: boolean) {
    this.isLoading = state;
  }
}
