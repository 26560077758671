<div>{{temp1}} </div>    
<div class="PreviewModel"  [ngClass]="{'middlePreviewModelPopup': appcomponentService.isPinChecked}">
<div class="dds-modal-overlay" (click)="closePreview()"></div>

          <div ddsResize class="dds-modal">
              <div class="dds-modal__header headerMargin">
                  <span class="dds-modal__title"> {{previewText}}
                </span>
                <ul class="whatsnew">
                    <li> {{whatsnew1}}
                        <a [href]="userCommunityUrl" target="_blank">{{ userCommunityText }}</a>                  
                    </li>
                    <li>{{whatsnew2}}</li>
                    <li>{{whatsnew3}}</li>
                    <li>{{whatsnew4}}</li>
                    <li>{{whatsnew5}}</li>
              </ul>
              </div>
              <div class="dds-modal__footer footerMargin">
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                      <div class="text-right">
                          <button class="btnNo" (click)="closePreview()">{{preview_option1}} </button>
                          <button class="btnYes" >{{preview_option2}} </button>
                      </div>
                  </div>
              </div>
          </div>