import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';
import { DocumentAnalyzerComponent } from './document-analyzer/document-analyzer.component';

const routes: Routes = [
  {path: '', component:HomeComponent,canActivate: [MsalGuard]  }, 
  {path:"document-analyzer",component:DocumentAnalyzerComponent,canActivate: [MsalGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }