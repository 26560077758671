import { Component } from '@angular/core';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  private destroy = new Subject<void>();
  constructor(private i18n: I18nService){}

  lblError: string;
  lblAccess: string;

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblError = "Erreur";
            this.lblAccess = "Vous n'avez pas accès à cette page";
            break;
          default:
            this.lblError = "Error";
            this.lblAccess = "You don't have access to this page";
            break;

        }
        // alert(locale)
      });
  }

  ngOnInit() {
    // debugger;
    // this.lstDislikeOptions = ["AI hallucination: false information was provided", "AI did not understand the provided context correctly", "AI deviated from my instructions", "AI response too generic / irrelevant to the query", "Other (please specify in the comment below)"]
    // console.log(this.lstDislikeOptions)
    this.manageLocale();
  }

}
