import { Injectable } from '@angular/core';
import { valueAccessor } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppComponentService {
    isLoading: boolean = false;
    systemSettings: any;
    systemModel: string;
    isMainContentDisables: boolean = false; // Flag to disable the main content
    isModelOpen: boolean = false; // Flag to check if the model is open
    isPinChecked: boolean = false; // Flag to check if the pin is checked
    private triggerNewChatFunction = new Subject<void>();
    triggerNewChatFunction$ = this.triggerNewChatFunction.asObservable();
    isSendButtonDisabled: boolean = false; // Flag to disable the send button
    setLoadingState(state: boolean) {
        this.isLoading = state;
    }
    setSystemSettings(obj: any) {
        this.systemSettings = obj;
        // set system settings
    }
    setSystemModel(model: string) {
        this.systemModel = model;
    }    
    // Method to trigger the function
    NewChatFunction() {
        this.triggerNewChatFunction.next();  // Emit the event
    }
    // Method to set isModelOpen flag
    setIsModelOpen(value: boolean) {
        this.isModelOpen = value;
    }

    setIsPinChecked(value:boolean)  {
        this.isPinChecked = value;
    }
    setIsSendButtonDisabled(value: boolean) {
        this.isSendButtonDisabled = value;
    }
}
