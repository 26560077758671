import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { FeedbackService } from '../Services/feedback.service';
import { HttpClient } from '@angular/common/http';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-feedback-like',
  templateUrl: './feedback-like.component.html',
  styleUrls: ['./feedback-like.component.css']
})

export class FeedbackLikeComponent {
  txtFeedback: any;
  feedbackRes: any = "";
  feedbackLike: boolean = false;
  private destroy = new Subject<void>();
  selectedLanguage: string = 'EN';
  lblFeedback: string;
  lblLikedFeedbackInParticular: string;
  lblSubmit: string;

  updateCharacterCount() { }

  constructor(public modalService: ModalService,
    private apiService: FeedbackService, private http: HttpClient, private i18n: I18nService, public appcomponentService: AppComponentService) { }

  @Input() lstlikeOptions: any;
  @Input() FeedbackLike: any = [];
  @Input() isFooter: boolean;
  @Input() size: string;
  @Input() isInverse: boolean;


  close() {
    var model = {
      "feedback": "positive",
      "feedbackType": "positive",
      "document_Name": this.lstlikeOptions.documentNames || [],
      "userQuery": this.lstlikeOptions.userQuery,
      "apiResponse": this.lstlikeOptions.apiResponse,
      "gptModel": this.lstlikeOptions.gptModel,
      "conversationId": this.lstlikeOptions.convid,
      "selectedOption": []
    }
    console.log("Feedback model", model);

    this.apiService.submitFeedbackData(model)
      .subscribe(res => {
        console.log("Feedback response", res);
        this.modalService.close();
      },
        (err) => {
          this.modalService.close();
        });
    this.feedbackLike = true;
    console.log("like res", model);
  }

  Submit() {
    console.log("lstlikeOptions", this.lstlikeOptions)
    var feedRes = (this.feedbackRes == null) || (this.feedbackRes.trim() == "") ? 'positive' : this.feedbackRes;
    var userQuery = this.lstlikeOptions.userQuery;

    if (!userQuery || userQuery.trim() === '') {
      userQuery = "summary";
    }

    var model = {
      "feedback": feedRes,
      "feedbackType": "positive",
      "document_Name": this.lstlikeOptions.document_Name || [],
      "userQuery": userQuery,
      "apiResponse": this.lstlikeOptions.apiResponse,
      "gptModel": this.lstlikeOptions.gptModel,
      "conversationId": this.lstlikeOptions.convid,
      "selectedOption": []
    }

    console.log("Feedback model before submission:", model);

    if (this.lstlikeOptions.isDocAnalyzerActive) {
      // if(this.lstlikeOptions.isDocAnalyzerActive){
      console.log("submitting Doc Analyzer Feedback model", model);
      console.log("Document names being submitted:", model.document_Name);
      this.apiService.submitDocFeedbackData(model)
        .subscribe(res => {
          console.log("Doc Analyzer Feedback response", res);


          this.modalService.close();
        },
          (err) => {
            console.log("Doc Analyzer Feedback error", err);
            console.log("Full error response:", JSON.stringify(err, null, 2));
            console.log("Error details:", err.error);
            this.modalService.close();
          }
        );
    }
    else {
      console.log("Submitting general feedback");
      this.apiService.submitFeedbackData(model)
        .subscribe(res => {
          console.log("Feedback response", res);
          this.modalService.close();
        },
          (err) => {

            this.modalService.close();
          });
      this.feedbackLike = true;
      console.log("like res", model);
    }
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblFeedback = "Commentaires";
            this.lblLikedFeedbackInParticular = "Qu'avez-vous aimé dans cette réponse en particulier ?";
            this.lblSubmit = "Soumettre";
            break;

          default:
            this.lblFeedback = "Feedback";
            this.lblLikedFeedbackInParticular = "What did you like about this response in particular?";
            this.lblSubmit = "Submit";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit() {
    //centering feedback like modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentService.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentService.setIsPinChecked(true);
      }
      else {
        this.appcomponentService.setIsPinChecked(false);
      }
    }
    this.manageLocale();
  }
}