import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

import { LabelPosition, Size, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';

import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { SharingmessageService } from '../Services/sharingmessage.service';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-systemsettings',
  templateUrl: './systemsettings.component.html',
  styleUrls: ['./systemsettings.component.css']
})
export class SystemsettingsComponent implements OnInit {
  private destroy = new Subject<void>();
  selectedLanguage: string = 'EN';
  lblChatSettings: string;
  btnClose: string;
  btnApply: string;
  isSidebarPinned: boolean;

  constructor(public modalService: ModalService, private i18n: I18nService, private sharingmessageService: SharingmessageService
    , public appcomponentservice: AppComponentService
  ) { }
  @Input() isFooter: boolean;
  @Input() size: string;
  close() {
    this.modalService.close();
  }

  onApply() {
    this.modalService.close(this.systemSettingsData)

  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblChatSettings = "Paramètres de Chat";
            this.textareaOptions.placeholder = "Dites-moi comment je devrais interagir avec vous. Par exemple, comment devrais-je vous appeler, devrais-je poser des questions de suivi ou faire des suppositions éclairées, détaillées ou concises";
            this.textareaOptions.label = "Instructions supplémentaires";
            this.personalitySelectOptions.label = "Personnalité";
            this.personalitySelectItems[0].heading = "Déterministe";
            this.personalitySelectItems[1].heading = "Professionnel";
            this.personalitySelectItems[2].heading = "Créatif";
            this.responseLengthSelectOptions.label = "Longueur de la réponse";
            this.responseLengthSelectItems[0].heading = "Concise";
            this.responseLengthSelectItems[1].heading = "Équilibré";
            this.responseLengthSelectItems[2].heading = "Détaillé";
            this.btnClose = "Fermer";
            this.btnApply = "Appliquer";
            break;

          default:
            this.lblChatSettings = "Chat Settings";
            this.textareaOptions.placeholder = "Tell me how should I interact with you. For example how should I address you, should I ask follow up questions or take educated guess, elaborate or concise";
            this.textareaOptions.label = "Additional Instructions";
            this.personalitySelectOptions.label = "Personality";
            this.personalitySelectItems[0].heading = "Deterministic";
            this.personalitySelectItems[1].heading = "Professional";
            this.personalitySelectItems[2].heading = "Creative";
            this.responseLengthSelectOptions.label = "Response Length";
            this.responseLengthSelectItems[0].heading = "Concise";
            this.responseLengthSelectItems[1].heading = "Balanced";
            this.responseLengthSelectItems[2].heading = "Elaborate";
            this.btnClose = "Reset";
            this.btnApply = "Apply";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit() {
    //centering chat settings modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
    
    this.manageLocale();
    if (this.appcomponentservice.systemSettings.systemMessage == undefined || this.appcomponentservice.systemSettings.systemMessage == null || this.appcomponentservice.systemSettings.systemMessage == "")
      this.systemSettingsData.systemprompt = '';
    else
      this.systemSettingsData.systemprompt = this.appcomponentservice.systemSettings.systemMessage;

    if (this.appcomponentservice.systemSettings.responseStyle == undefined || this.appcomponentservice.systemSettings.responseStyle == null || this.appcomponentservice.systemSettings.responseStyle == 0)
      this.systemSettingsData.personality = 0.5;
    else
      this.systemSettingsData.personality = this.appcomponentservice.systemSettings.responseStyle;

    if (this.appcomponentservice.systemSettings.userQueryLength == undefined || this.appcomponentservice.systemSettings.userQueryLength == null || this.appcomponentservice.systemSettings.userQueryLength == "")
      this.systemSettingsData.responselength = '-provide medium length and balanced response';
    else
      this.systemSettingsData.responselength = this.appcomponentservice.systemSettings.userQueryLength;
  }
  systemSettingsData: any = {
    systemprompt: '',
    personality: '',
    responselength: ''
  }
  textareaOptions: TextareaOptions = {
    label: 'Additional Instructions',
    labelPosition: LabelPosition.external,
    placeholder: 'Tell me how should I interact with you. For example how should I address you, should I ask follow up questions or take educated guess, elaborate or concise',
    minHeight: 250,
    isRequired: false,
    isError: false,
    errorState: ErrorState.default,
    description: '',
    customClass: '',
    errorMessage: '',
    maxLength: 300,
    maxLengthPosition: 'bottom-right',
    size: Size.sm,
    isDisabled: false

  };

  personalitySelectOptions: SelectOptions = {
    label: 'Personality',
    labelPosition: LabelPosition.external,
    description: '',
    placeholder: 'Professional',
    size: Size.md,
    isDisabled: false,
    isResponsive: false,
    isRequired: false,
    isError: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: ""
  };

  personalitySelectItems: SelectItemOptions[] = [

    {
      heading: "Deterministic",
      value: 0.1 //set to 0 instead of 0 as 0 is considered as empty in dropdown
    },
    {
      heading: "Professional",
      value: 0.5
    },
    {
      heading: "Creative",
      value: 1
    }
  ];

  responseLengthSelectOptions: SelectOptions = {
    label: 'Response Length',
    labelPosition: LabelPosition.external,
    description: '',
    placeholder: '',
    size: Size.md,
    isDisabled: false,
    isResponsive: false,
    isRequired: false,
    isError: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: ""
  };

  responseLengthSelectItems: SelectItemOptions[] = [

    {
      heading: 'Concise',
      value: '-provide very short length and precise with your response'
    },
    {
      heading: 'Balanced',
      value: '-provide medium length and balanced response'
    },
    {
      heading: 'Elaborate',
      value: '-provide very long length and elaborate with your response'
    }
  ];
  reset() {
    this.systemSettingsData = {};
    setTimeout(() => {
      this.systemSettingsData = {
        systemprompt: '',
        personality: 0.5,
        responselength: '-provide medium length and balanced response'
      };
    }, 100);

    // this.systemSettingsData.systemprompt='';
    // this.systemSettingsData.personality=0.5;
    // this.systemSettingsData.responselength='-provide medium length and balanced response';
  }
  setPersionality(event) {
    this.systemSettingsData.personality = event;
  }
  setResponseLength(event) {
    this.systemSettingsData.responselength = event;
  }
}
