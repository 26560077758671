import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
import { HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { Observable } from 'rxjs';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {
  gptModel: any;
  userQuery: any; 
  switch_value: boolean = false;
  constructor(private httpClient: HttpClient,private https: HttpClient) { }
  blobUrl = protectedResources.uploadDocument.endpoint;
  docupUrl = protectedResources.processUploadedDocument.endpoint;
uploadDocument(formData: FormData): any {
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.post(  
    this.blobUrl,    
    formData   
   );
  }
  ProcessDocuments():  Observable<any> {
    return this.httpClient.post(  
      this.docupUrl+"ProcessDocuments",{});
    }

  GenerateEmbeddings():  Observable<any>{
      return this.httpClient.post(this.docupUrl+"GenerateEmbeddings", {});   
    }

  ShowSummary():  Observable<any> {
      return this.httpClient.post(  
        this.docupUrl+"ShowSummary",{});
      }

}
