<!DOCTYPE html>
<html>
<head>
  <title>{{lblError}}</title>
 </head>
<body>
  <div class="error-message">
    <h1>{{lblAccess}}</h1>
  </div>
</body>
</html>
