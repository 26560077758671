import { Component, Input } from '@angular/core';
import { CheckboxOptions, CheckboxGroupOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { Themes, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { FeedbackService } from '../Services/feedback.service';
import { HttpClient } from '@angular/common/http';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from '../app.component';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-feedback-dis-like',
  templateUrl: './feedback-dis-like.component.html',
  styleUrls: ['./feedback-dis-like.component.css']
})
export class FeedbackDisLikeComponent {
  private destroy = new Subject<void>();
  feedbackRes: any = "";
  updateCharacterCount() { }
  constructor(private modalService: ModalService,
    private apiService: FeedbackService, private http: HttpClient, private i18n: I18nService, public appcomponentService: AppComponentService) { }

  @Input() isFooter: boolean;
  @Input() size: string;
  @Input() isInverse: boolean;

  // open() {
  //   this.modalService.open(CustomModal2Component);
  // }

  // close() {
  //   this.modalService.close();
  // }

  lblFeedback: string;
  lblGoodResponse: string;
  lblCheckbox1: string;
  lblCheckbox2: string;
  lblCheckbox3: string;
  lblCheckbox4: string;
  lblCheckbox5: string;
  lblAdditionalComment: string;
  lblSubmit: string;
  lstSelectedOptions: any = [];
  txtFeedback: any = "";
  feedbackDisLike: boolean = false;
  checkboxState1: boolean = false;
  checkboxState2: boolean = false;
  checkboxState3: boolean = false;
  checkboxState4: boolean = false;
  checkboxState5: boolean = false;

  groupOptions: CheckboxGroupOptions = {
    title: 'Title',
    isRequired: false,
    isInverse: false,
  };
  @Input() lstoptionDislike: any;
  @Input() lstDislikeOptions: any;

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblFeedback = "Commentaires";
            this.lblGoodResponse = "Pourquoi cette réponse n'était-elle pas bonne?";
            this.lblCheckbox1 = "Hallucination de l'IA : des informations fausses ont été fournies";
            this.lblCheckbox2 = "L'IA n'a pas compris correctement le contexte fourni";
            this.lblCheckbox3 = "L'IA a dévié de mes instructions";
            this.lblCheckbox4 = "Réponse de l'IA trop générique / non pertinente par rapport à la requête";
            this.lblCheckbox5 = "Autre (veuillez préciser dans le commentaire ci-dessous)";
            this.lblAdditionalComment = "Commentaire supplémentaire";
            this.lblSubmit = "Soumettre";
            break;
          default:
            this.lblFeedback = "Feedback";
            this.lblGoodResponse = "Why was this not a good response?";
            this.lblCheckbox1 = "AI hallucination: false information was provided";
            this.lblCheckbox2 = "AI did not understand the provided context correctly";
            this.lblCheckbox3 = "AI deviated from my instructions";
            this.lblCheckbox4 = "AI response too generic / irrelevant to the query";
            this.lblCheckbox5 = "Other (please specify in the comment below)";
            this.lblAdditionalComment = "Additional Comment";
            this.lblSubmit = "Submit";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit() {
    // this.lstDislikeOptions = ["AI hallucination: false information was provided", "AI did not understand the provided context correctly", "AI deviated from my instructions", "AI response too generic / irrelevant to the query", "Other (please specify in the comment below)"]
    // console.log(this.lstDislikeOptions)
    //centering feedback dislike modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentService.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentService.setIsPinChecked(true);
      }
      else {
        this.appcomponentService.setIsPinChecked(false);
      }
    }
    this.manageLocale();
  }

  options: CheckboxOptions = {
    label: 'Label',
    theme: Themes.green,
    isIndeterminate: false,
    isDisabled: false,
    isError: false,
    isRequired: false,
    errorState: ErrorState.default,
    ariaLabel: '',
    customClass: ''
  };

  // stateChanged(state: boolean, item: string) {
  //   var optionIdx = this.lstSelectedOptions.findIndex((x: any) => x == item);
  //   if (optionIdx != -1) {
  //     if (state)
  //       this.lstSelectedOptions.push(item);
  //     else
  //       this.lstSelectedOptions = this.lstSelectedOptions.filter((x: any) => x !== item);
  //   }
  //   else {
  //     this.lstSelectedOptions.push(item);
  //   }

  // }

  stateChanged(state: boolean, item: string) {
    if (state) {
      if (!this.lstSelectedOptions.includes(item)) {
        this.lstSelectedOptions.push(item);  // Add to list if checked
      }
    } else {
      this.lstSelectedOptions = this.lstSelectedOptions.filter(x => x !== item);  // Remove from list if unchecked
    }
    console.log(this.lstSelectedOptions);  // Log or use the updated lstSelectedOptions
  }

  close() {
    var model = {
      "feedback": "negative",
      "feedbackType": "negative",
      "document_Name": this.lstoptionDislike.documentNames || [],
      "userQuery": this.lstoptionDislike.userQuery,
      "apiResponse": this.lstoptionDislike.apiResponse,
      "gptModel": this.lstoptionDislike.gptModel,
      "conversationId": this.lstoptionDislike.convid,
      "selectedOption": this.lstSelectedOptions
    }
    this.apiService.submitFeedbackData(model)
      .subscribe(res => {
        this.modalService.close();
      },
        (err) => {
          this.modalService.close();
        });
    this.feedbackDisLike = true;
  }

  Submit() {
    console.log("lstoptiondislike:", this.lstoptionDislike)
    var feedRes = (this.txtFeedback == null) || (this.txtFeedback.trim() == "") ? 'negative' : this.txtFeedback;
    var userQuery = this.lstoptionDislike.userQuery;
    if (!userQuery || userQuery.trim() === '') {
      userQuery = "summary";
    }
    var model = {
      "feedback": feedRes,
      "feedbackType": "negative",
      "document_Name": this.lstoptionDislike.document_Name || [],
      // "document_Name": [],
      "userQuery": userQuery,
      "apiResponse": this.lstoptionDislike.apiResponse,
      // "apiResponse":"testing api response",
      "gptModel": this.lstoptionDislike.gptModel,
      // "gptModel":"testing gpt model",
      "conversationId": this.lstoptionDislike.convid,
      // "conversationId":"testing convid",
      "selectedOption": this.lstSelectedOptions
    }
    if (this.lstoptionDislike.isDocAnalyzerActive) {
      this.apiService.submitDocFeedbackData(model)
        .subscribe(res => {
          console.log("Doc Analyzer Feedback response", res);
          this.modalService.close();
        },
          (err) => {
            console.log(" error Doc Analyzer Feedback response", err);
            this.modalService.close();
          });
    }

    else {

      this.apiService.submitFeedbackData(model)
        .subscribe(res => {
          console.log("Feedback response", res);
          this.modalService.close();
        },
          (err) => {
            this.modalService.close();
          });
      this.feedbackDisLike = true;

    }
  }
}